<template>
  <!-- @update:show="(val) => $store.commit('set', ['restaurantsAsideShow', val])" -->
  <CSidebar
    aside
    :show="$store.state.restaurantsAsideShow"
    @update:show="(val) => $store.commit('set', ['restaurantsAsideShow', val])"
    colorScheme="light"
    overlaid
    size="lg"
  >
    <div class="scrollable">
      <div class="d-flex my-1">
        <div class="flex-grow-1" v-if='!showErrorAlert'>
          <div class="logo-container">
            <CLink
              v-if="typeof item.id !== 'undefined'"
              :to="{name: 'View Restaurant',params: { id: item.id }}"
              target="_blank"
            >
              <CImg
                  v-show="item.restaurant_logo"
                  :src="getValidImageUrl(item.restaurant_logo_path)"
                  class="rounded mx-auto restaurant-logo"
                  placeholderColor="lightgray"
              />
            </CLink>
          </div>
        </div>

        <div>
          <CSidebarClose @click.native="$store.commit('toggle', 'restaurantsAsideShow')" />
        </div>
      </div>


      <!-- Error Alert -->
      <CListGroup class="list-group-accent mt-5" v-if='showErrorAlert'>
        <CListGroupItem class="list-group-item-accent-secondary bg-light text-danger text-center font-weight-bold text-uppercase">
          Error
        </CListGroupItem>

        <CListGroupItem href="#" class="list-group-item-accent-danger list-group-item-divider">
          <div class="c-avatar float-left mr-2">
            <CIcon name="cil-bug" size="xl" class="c-avatar-img text-danger"/>
          </div>
          <div>{{ errorAlertMessage }}</div>
        </CListGroupItem>
      </CListGroup>
      <!-- / Error Alert -->

      <CListGroup class="list-group-accent" v-if='!showErrorAlert'>
        <!-- Restaurant Name -->
        <CListGroupItem class="list-group-item-accent-secondary bg-light text-center font-weight-bold text-uppercase" v-if="(typeof item.id !== 'undefined')">
          <div>{{ item.restaurant_name }} (#{{ item.id }})</div>

          <div>
            <CButton target="_blank" size="sm" color="primary" shape="pill"
              :to="{name: 'View Restaurant',params: { id: item.id }}"
              v-c-tooltip="{
                // content: this.$router.resolve({name: 'View Restaurant',params: { id: item.id }}).href,
                content: 'View',
                placement: 'left',
              }"
            >
              <CIcon name="cil-restaurant" />
            </CButton>

            <CButton class="ml-2" size="sm" color="info" shape="pill"
              :to="{ name: 'Edit Restaurant', params: { id: item.id } }"
              v-c-tooltip="{
                content: 'Edit',
                placement: 'right',
              }"
            >
              <CIcon name="cil-align-left" />
            </CButton>
          </div>
        </CListGroupItem>


        <!-- Unsigned Contract -->
        <CListGroupItem v-if="item && !item.upload_contract" class="list-group-item-accent-warning table-warning p-0">
          <div class="d-flex align-items-center">
            <UnsignedContract
              :id="item.id"
              :email="item.email"
            />
          </div>
        </CListGroupItem>

        <!-- Delivery Service -->
        <CListGroupItem v-if="item.hasdeliveryService" class="list-group-item-accent-success table-success p-0">
          <CAlert color="success" class="d-flex flex-gap-2 align-items-center mb-0 h-100">
            <CIcon name="cil-truck" size="2xl" />
            <div>
              <strong>Delivery Service enabled.</strong>
              <div v-if="!item.hubs_count" class="d-flex flex-gap-2 align-items-center text-danger font-weight-bold flash-it">
                ⚠ Add the postcode ({{ item.restaurant_postcode }}) to the scope of a hub.
              </div>
            </div>
          </CAlert>
        </CListGroupItem>

        <!-- Update Restaurant Status -->
        <CListGroupItem :class='item.none_active ? "list-group-item-accent-danger table-danger" : "list-group-item-accent-success table-success"'>
          <div class="d-flex align-items-center">
            <CIcon name="cil-arrow-right" class="mr-2"/><strong class="text-muted mr-2">Not Published</strong>
            <CSwitch
              :checked.sync="item.none_active"
              @update:checked="updateRestaurantStatus"
              labelOn="YES"
              labelOff="NO"
              color="danger"
              shape="pill"
              size="lg"
            />
          </div>
        </CListGroupItem>


        <!-- Device Link -->
        <CListGroupItem class="list-group-item-accent-info list-group-item-divider">
          <div><strong>Device Link</strong></div>
          <CRow>
            <CCol col="12">
              <h6 class="text-muted mr-3">
                <CIcon name="cil-arrow-right" class="mr-2"/>
                {{ this.linkedDevice ? this.linkedDevice : 'No device linked yet!' }}
              </h6>
            </CCol>

            <CCol col="12" v-if="linkedDevice">
              <CButton
                color="danger"
                size="sm"
                variant="ghost"
                shape="pill"
                @click="unlinkDevice"
              >
                <CIcon name="cil-x" /> Unlink Device
              </CButton>
            </CCol>

            <CCol col="12" class="border-shadow p-2" v-else>
              <CTabs :active-tab.sync="activeTab">
                <CTab v-if="previousRestDevice" title="Get From the Previous" class="aside-tab-item">
                  <ul class="py-2">
                    <li>Previous Restaurant ID: #{{ previousRestDevice.restaurant_id }}</li>
                    <li>Device ID: #{{ previousRestDevice.device?.device?.sid }}</li>
                    <li>Software: {{ previousRestDevice.device?.software ? 'YES' : 'NO' }}</li>
                  </ul>

                  <CButton
                    type="submit"
                    color="success"
                    size="sm"
                    shape="pill"
                    @click="linkDevice($event, previousRestDevice)"
                  >
                    <CIcon name="cil-pin" /> Link Device
                  </CButton>
                </CTab>

                <CTab title="Select New" class="aside-tab-item">
                  <CForm
                    class="pt-1"
                    @submit="linkDevice"
                  >
                    <v-select
                      class="mb-3"
                      v-model="selectedDevice"
                      :options="availableDevices"
                      placeholder="Please choose a device.."
                      :searchable="true"
                    >
                      <template #search="{attributes, events}">
                        <input
                          class="vs__search"
                          :required="!selectedDevice"
                          v-bind="attributes"
                          v-on="events"
                        />
                      </template>
                    </v-select>

                    <CButton
                      type="submit"
                      color="success"
                      size="sm"
                      shape="pill"
                    >
                      <CIcon name="cil-pin" /> Link Device
                    </CButton>
                  </CForm>
                </CTab>
              </CTabs>
            </CCol>
          </CRow>
        </CListGroupItem>


        <!-- Account Balance -->
        <CListGroupItem
          class="list-group-item-accent-dark list-group-item-divider"
          v-if="typeof item.id !== 'undefined'"
          :to="{
            name: 'RestaurantPaymentList',
            params: { restaurant_id: item.id },
          }"
        >
          <div><strong>Account Balance</strong></div>
          <h4 class="text-muted mr-3">
            <CIcon name="cil-arrow-right" class="mr-2"/>{{ item.account_balance }}
          </h4>
        </CListGroupItem>


        <!-- Orders -->
        <CListGroupItem
          class="list-group-item-accent-dark list-group-item-divider"
          v-if="typeof item.id !== 'undefined'"
          :to="{
            name: 'RestaurantOrderList',
            params: { restaurant_id: item.id },
          }"
        >
          <div><strong>Orders</strong></div>
          <h4 class="text-muted mr-3">
            <CIcon name="cil-arrow-right" class="mr-2"/>{{ item.restaurant_orders_count }}
          </h4>
        </CListGroupItem>


        <!-- Reviews -->
        <CListGroupItem
          class="list-group-item-accent-dark list-group-item-divider"
          v-if="typeof item.id !== 'undefined'"
          :to="{
            name: 'RestaurantReviews',
            params: { restaurant_id: item.id },
          }"
        >
          <div><strong>Reviews</strong></div>
          <h4 class="text-muted mr-3">
            <CIcon name="cil-arrow-right" class="mr-2"/>{{ item.restaurant_reviews_count }}
          </h4>
        </CListGroupItem>


        <!-- Zip Codes -->
        <CListGroupItem
          class="list-group-item-accent-dark list-group-item-divider"
          v-if="typeof item.id !== 'undefined'"
          :to="{
            name: 'RestaurantLinkedZIPCodes',
            params: { restaurant_id: item.id },
          }"
        >
          <div><strong>Zip Codes</strong></div>
          <h4 class="text-muted mr-3">
            <CIcon name="cil-arrow-right" class="mr-2"/>{{ item.zip_codes_linked_restaurants_count }}
          </h4>
        </CListGroupItem>


        <!-- Linked Types -->
        <CListGroupItem
          class="list-group-item-accent-dark list-group-item-divider"
          v-if="typeof item.id !== 'undefined'"
          :to="{
            name: 'RestaurantLinkedTypes',
            params: { restaurant_id: item.id },
          }"
        >
          <div><strong>Linked Types</strong></div>
          <h4 class="text-muted mr-3">
            <CIcon name="cil-arrow-right" class="mr-2"/>{{ item.restaurant_linked_types_count }}
          </h4>
        </CListGroupItem>


        <!-- Linked Categories -->
        <CListGroupItem
          class="list-group-item-accent-dark list-group-item-divider"
          v-if="typeof item.id !== 'undefined'"
          :to="{
            name: 'RestaurantLinkedCategories',
            params: { restaurant_id: item.id },
          }"
        >
          <div><strong>Categories</strong></div>
          <h4 class="text-muted mr-3">
            <CIcon name="cil-arrow-right" class="mr-2"/>{{ item.categories_count }}
          </h4>
        </CListGroupItem>


        <!-- Linked Menus -->
        <CListGroupItem
          class="list-group-item-accent-dark list-group-item-divider"
          v-if="typeof item.id !== 'undefined'"
          :to="{
            name: 'RestaurantLinkedMenus',
            params: { restaurant_id: item.id },
          }"
        >
          <div><strong>Menus</strong></div>
          <h4 class="text-muted mr-3">
            <CIcon name="cil-arrow-right" class="mr-2"/>{{ item.menus_count }}
          </h4>
        </CListGroupItem>


        <!-- Menu Sjabloons -->
        <CListGroupItem
          class="list-group-item-accent-dark list-group-item-divider"
          v-if="typeof item.id !== 'undefined'"
          :to="{
            name: 'Restaurant Menu Sjabloons',
            params: { restaurant_id: item.id },
          }"
        >
          <div><strong>Menu Sjabloons</strong></div>
          <h4 class="text-muted mr-3">
            <CIcon name="cil-arrow-right" class="mr-2"/>{{ item.menu_sjabloons_count }}
          </h4>
        </CListGroupItem>


        <!-- Tasks -->
        <CListGroupItem
          class="list-group-item-accent-dark list-group-item-divider"
          v-if="typeof item.id !== 'undefined'"
          :to="{
            name: 'RestaurantTaskList',
            params: { restaurant_id: item.id },
          }"
        >
          <div><strong>Tasks</strong></div>
          <h4 class="text-muted mr-3">
            <CIcon name="cil-arrow-right" class="mr-2"/>
            {{ item.open_tasks_count }} / {{ item.tasks_count }}
          </h4>
        </CListGroupItem>

        <!-- Permissions -->
        <CListGroupItem
          class="list-group-item-accent-dark list-group-item-divider"
          v-if="typeof item.id !== 'undefined'"
          :to="{
            name: 'RestaurantPermissionList',
            params: { restaurant_id: item.id },
          }"
        >
          <div><strong>Permissions</strong></div>
          <h4 class="text-muted mr-3">
            <CIcon name="cil-arrow-right" class="mr-2"/>
            {{ item.permissions_count }}
          </h4>
        </CListGroupItem>
      </CListGroup>

      <CElementCover :opacity="0.2" v-show="loading"/>
    </div>
  </CSidebar>
</template>

<script>

export default {
  name: "TheAside",
  props: ['id'],
  components: {
    UnsignedContract: () => import('@/views/restaurants/sign-contract/UnsignedContract.vue'),
  },
  data() {
    return {
      loading: false,
      item: [],
      linkedDevice: null,

      // Alert işlemleri için
      errorAlertMessage: '',
      showErrorAlert: false,

      availableDevices: [],
      selectedDevice: null,
      activeTab: 0,
    };
  },
  computed: {
    previousRestDevice() {
      const { previous } = this.item;
      const linkWithDevice = previous?.restaurant_device_link_with_device;

      if (linkWithDevice) {
        return linkWithDevice;
      }

      return null;
    }
  },

  mounted() {
    this.getAvailableDevices();
  },

  watch: {
    '$store.state.restaurantsAsideShow': function (val) {
      if (val) {
        this.selectedDevice = null;
        this.showErrorAlert = false;
        this.getValues();
        this.activeTab = 0;
      }
    },
  },

  methods: {
    getValues() {
      this.loading = true;
      this.$axios
        .get(
          this.$backend.RESTAURANT.SHOW.replace("{id}", this.id)
        )
        .then((response) => {
          this.item = response.data;
          this.linkedDevice = response.data.restaurant_device_link_with_device ? response.data.restaurant_device_link_with_device.device?.sid : null;
        })
        .catch((error) => {
          this.errorAlertMessage = error.response.data.message
          this.showErrorAlert = true
        })
        .finally(() => {
          this.loading = false;
        });
    },

    getValidImageUrl(url) {
      const ORIGIN_CDN = process.env.VUE_APP_ORIGIN_CDN;

      return url
        ? this.$globalFunc.isURL(url)
          ? url
          : ORIGIN_CDN + url
        : "";
    },

    getAvailableDevices() {
      this.$axios
        .get(this.$backend.DEVICE.AVAILABLE_DEVICES)
        .then((response) => {
          this.availableDevices = Object.keys(response.data).map((key) => {
            return response.data[key].sid;
          });
        });
    },

    linkDevice(e, previousRestDevice = null) {
      e.preventDefault();
      this.loading = true;
      this.$axios
        .post(this.$backend.RESTAURANT.LINK_DEVICE, {
          restaurant_id: this.id,
          device_sid: previousRestDevice
            ? previousRestDevice.device_id
            : this.selectedDevice,
          previous_restaurant_id: previousRestDevice?.restaurant_id,
        })
        .then((response) => {
          this.selectedDevice = response.data.data.device_sid;

          this.availableDevices = this.availableDevices.filter(
            (item) => item !== this.selectedDevice
          );
          this.linkedDevice = this.selectedDevice;
        })
        .finally(() => {
          this.loading = false;
          this.getAvailableDevices(); // authored by Administrator
          this.$emit("updated", this.item);
        });
    },
    unlinkDevice() {
      this.$confirm({
        title: 'Confirm',
        message: `⚠️ Do you really want to unlink the device?`,
        button: {
          no: "No",
          yes: "Yes",
        },
        /**
         * Callback Function
         * @param {Boolean} confirm
         */
        callback: (confirm) => {
          if (confirm) {
            // ---------------
            this.loading = true;
            this.$axios.post(this.$backend.RESTAURANT.UNLINK_DEVICE, { 'restaurant_id':this.id, 'device_sid':this.linkedDevice })
              .then(() => {
                this.availableDevices.push(this.linkedDevice);
                this.availableDevices.sort();

                this.selectedDevice = this.linkedDevice;
                this.linkedDevice = null;
              })
              .finally(() => {
                this.loading = false;
                this.getAvailableDevices(); // authored by Administrator
                this.$emit("updated", this.item);
              });
            // ---------------
          }
          // Sidebar'ı açık tut.
          this.$store.commit('set', ['restaurantsAsideShow', true]);
        },
      });
    },

    updateRestaurantStatus(none_active) {
      let isPublished = !none_active;

      if (isPublished && this.item.published_previous_count > 0) {
        this.$confirm({
          title: "Confirm",
          message: `⚠️ The restaurant has previous restaurant. Do you want to unpublish this?`,
          button: { no: "No", yes: "Yes" },
          /**
           * Callback Function
           * @param {Boolean} confirm
           */
          callback: this.updateActive,
        });
      } else {
        this.updateActive();
      }
    },
    updateActive(unpublishPrevious = false) {
      this.loading = true;

      this.$axios
        .post(this.$backend.RESTAURANT.PUBLISH.replace("{id}", this.id), {
          'none-active': this.item.none_active,
          'unpublish-previous': unpublishPrevious,
        })
        .then(() => {
          this.$emit("updated", this.item);
          this.$store.commit('set', ['restaurantsAsideShow', this.item.none_active]);
        })
        .catch((error) => {
          this.item.none_active = !this.item.none_active;
          this.$toast.error(`${error.response.data.message}`);
          // this.errorAlertMessage = error.response.data.message;
          // this.showErrorAlert = true;
        })
        .finally(() => {
          this.loading = false;
        });
    },
  }
};
</script>

<style>
.aside-tab-item > a {
  height: 32px !important;
  padding: 5px !important;
}
</style>

<style scoped>
.border-shadow {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.logo-container {
  height: 100px;
}
.logo-container a {
  display: block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.restaurant-logo {
  display: block;
  max-width: 100px;
  max-height: 100px;
  width: auto;
  height: auto;
}
.scrollable {
  overflow-y: auto;
  height: 100%;
}
</style>
